import styled from "styled-components";
import { device } from "../global-styles/mediaQueries";

export const AboutUsWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundGray};
  padding: 450px 0 0px 0;

  svg {
    position: absolute;
    bottom: -100px;
    left: 0;
  }

  @media ${device.tabletL} {
    padding: 350px 0 60px 0;

    svg {
      bottom: -50px;
    }
  }

  @media ${device.desktopS} {

    svg {
      bottom: 0px;
      width: 100%;
      height: auto;
    }
  }

  @media ${device.desktopL} {
    padding: 450px 0 60px 0;
  }
`

export const Content = styled.div`

`

export const BackgroundElement = styled.div`
  background-color: white;
  height: 70px;

  @media ${device.tablet} {
    height: 50px;
  }

  @media ${device.tabletL} {
    height: 180px;
  }

  @media ${device.desktopS} {
    height: 220px;
  }

  @media ${device.desktopL} {
    height: 270px;
  }
`

export const TextContent = styled.div`
  position: relative;
  z-index: 2;

  h2 {
    margin-bottom: 15px;
    margin-top: 5px;
  }

  p {
    margin-bottom: 25px;
  }

  @media ${device.tablet} {

    h2 {
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 35px;
    }
  }

  @media ${device.tabletL} {
    max-width: 540px;
    margin: 0 auto;

    h2 {
      margin-bottom: 20px;
    }
  }

  @media ${device.desktop} {

    p {
      margin-bottom: 40px;
    }
  }
`

export const HexagonsWrapper = styled.div`
  display: none;

  @media ${device.desktopS} {
    display: block;
    position: absolute;
    bottom: -10vw;
    left: 0;

    svg {
      height: auto;
      width: 100vw;
    }
  }
`

export const Image = styled.img`
  position: relative;
  margin-top: -50px;
  width: 100%;
  transform: translateY(50%);
  z-index: 2;

  @media ${device.tablet} {
    margin-top: -100px;
  }

  @media ${device.desktop} {
    margin-top: -150px;
  }

  @media ${device.desktopL} {
    margin-top: -220px;
  }
`
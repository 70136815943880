import React from 'react'
import styled from 'styled-components';
import { device } from './global-styles/mediaQueries';

import GoogleMapReact from 'google-map-react';
import customMapStyles from '../utils/customMapStyles';


const MapWrapper = styled.div`
  width: 100%;
  height: 400px;

  @media ${device.tablet} {
    height: 500px;
  }

  @media ${device.desktopS} {
    height: 500px;
  }

  @media ${device.desktopL} {
    height: 550px;
  }
`


const CustomPin = ({ text }: { text: string }) => {
  return (
    <svg style={{ cursor: "pointer" }} onClick={() => window.open('https://www.google.com/maps/place/Fluid+Lab/@45.8342111,20.4718228,18z/data=!3m1!4b1!4m6!3m5!1s0x4744df26d216f541:0x5953468bb63ee841!8m2!3d45.8342098!4d20.4726889!16s%2Fg%2F11btnjymr3?hl=en-GB')} width="40" height="47" viewBox="0 0 40 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1020_33)">
        <path d="M2.54155 15.3568C6.64572 -2.68489 33.3749 -2.66406 37.4582 15.3776C39.8541 25.9609 32.2708 36.6275 26.5 42.1692C22.3125 46.2109 17.7083 46.2109 13.5 42.1692C7.75 36.6275 0.145716 25.9401 2.54155 15.3568Z" fill="#9E459A" stroke="#9E459A" strokeWidth="3.125" />
        <path d="M24.3343 29.6931L24.13 12.6339L22.7456 11.3121L27.8328 11.2473V29.6931H24.3343V29.6931Z" fill="#F0E0EF" />
        <path d="M21.9094 11.0371L23.8068 12.7771V30.1343H30.5401V11.0371H21.9094ZM29.8893 29.4829H24.4581V28.2884H25.2776V27.9997H24.4581V26.6131H25.2776V26.3244H24.4581V24.9379H25.2776V24.6492H24.4581V23.2626H25.2776V22.9739H24.4581V21.5874H25.2776V21.2987H24.4581V19.9121H25.2776V19.6234H24.4581V18.2369H25.2776V17.9482H24.4581V16.5616H25.2776V16.2729H24.4581V14.8836H25.2776V14.5949H24.4581V12.4908L23.5836 11.689H29.8893V29.4829Z" fill="white" />
        <path d="M10.0483 30.1334V11.0371H19.7559V14.4225H13.5852V18.9191H19.4717V22.3045H13.5852V30.1343H10.0483V30.1334Z" fill="#F2E6F2" />
      </g>
      <defs>
        <clipPath id="clip0_1020_33">
          <rect width="40" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}


export default function Map() {

  const defaultProps = {
    center: {
      lat: 45.834233372472895,
      lng: 20.472690060940064
    },
    zoom: 17
  };

  const customPinProps = {
    lat: defaultProps.center.lat,
    lng: defaultProps.center.lng,
    text: "My Marker"
  }

  const mapOptions = {
    styles: customMapStyles
  }

  return (
    <MapWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY as string }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={mapOptions}
      >
        <CustomPin {...customPinProps} />
      </GoogleMapReact>
    </MapWrapper>
  )
}

import React from 'react'

import styled, { css } from 'styled-components';

import { ReactComponent as Facebook } from '../assets/facebook.svg'
import { ReactComponent as Linkedin } from '../assets/linkedIn.svg'


export const SocialsWrapper = styled.div<{ themeColor: 'white' | 'gray', sizeInPx: number }>`
  svg {
    width: ${({ sizeInPx }) => `${sizeInPx}px`};
    height: ${({ sizeInPx }) => `${sizeInPx}px`};
    
    
    path {
      ${({ theme, themeColor }) => themeColor === 'white' && css`
      fill: ${theme.colors.text};
      `}
    }
  }

  a {
    display: inline-block;
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

type FacebookIconProps = {
  themeColor: 'white' | 'gray';
  sizeInPx: number;
}

export default function Socials({ themeColor, sizeInPx }: FacebookIconProps) {
  return (
    <SocialsWrapper themeColor={themeColor} sizeInPx={sizeInPx}>
      <a
        href="https://www.facebook.com/fluidlabkikinda"
        target="_blank"
        rel='noreferrer'
      >
        <Facebook />
      </a>
      <a
        href="https://www.linkedin.com/company/fluidlabkikinda"
        target="_blank"
        rel='noreferrer'
      >
        <Linkedin />
      </a>
    </SocialsWrapper>
  )
}

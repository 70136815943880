import { ThemeProvider } from 'styled-components';
import { theme } from './components/global-styles/globalTheme'

import GlobalStyles from './components/global-styles/globalStyles';
import Homepage from './pages/Homepage';
import Layout from './components/Layout';
// import UnderConstruction from './components/UnderConstruction';


import './App.css'

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />

        <Layout>
          <Homepage />
          {/* <UnderConstruction /> */}
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default App;

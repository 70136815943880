import { useState, useEffect } from "react";

import { device } from '../components/global-styles/mediaQueries'

/**
 * Uses window.matchMedia (https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to check if given query matches document size.
 * It also monitors document changes with resize event listener and notifys if query matches screen size.
 * 
 * @param deviceSize - defined media query
 * 
 * @returns {Boolean} - whether the document size is bigger then the given query 
 */
const useMediaQuery = (deviceSize: 'mobile' | 'tablet' | 'tabletL' | 'desktopS' | 'desktop' | 'desktopL') => {

  const query = device[deviceSize]

  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const media: MediaQueryList = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    let timeoutId: NodeJS.Timeout;

    // using debounce, so state doesn't update on every resize event
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setMatches(media.matches), 80)
    }

    window.addEventListener("resize", resizeListener);

    return () => window.removeEventListener("resize", resizeListener);
  }, [matches, query]);

  return matches;
}

export default useMediaQuery;
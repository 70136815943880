import { DefaultTheme } from "styled-components";


export const theme: DefaultTheme = {
  colors: {
    element: '#E4B3E2',
    primary: '#CB56C6',
    white: '#ffffff',
    title: '#1F2630',
    text: '#565E6A',
    navLink: '#3F3A3F',
    lightGray: '#676e79',
    pinkText: '#9E459A',
    backgroundGray: '#F3F8FF'
  },
  fonts: {
    workSans: "Work Sans",
    inter: "Inter"
  },
  gradients: {
    text: 'linear-gradient(93.02deg, #CB56C6 -1.22%, #84379E 88.05%)'
  }
}
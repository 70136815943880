import styled from "styled-components";
import { device } from "../global-styles/mediaQueries";


export const HomeVisitsWrapper = styled.div`
  position: relative;
  transform: translateY(50%);
  margin-top: -300px;
  z-index: 10;

  @media ${device.tabletL} {
    margin-top: -150px;
  }

  @media ${device.desktopL} {
    margin-top: -200px;
  }
`

export const ContentContainer = styled.div`
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 40px 40px -40px rgba(0, 0, 0, 0.2);
  background-color: #EBF0F8;

  @media ${device.tabletL} {
    box-shadow: none;
  }
`

export const HomeVisitDesktopImage = styled.div<{ imageUrl: string }>`
  display: none;
  
  @media ${device.tabletL} {
    display: block;
    width: 100%;
    height: 330px;
    border-radius: 12px;
    background-image: ${({ imageUrl }) => `url(${imageUrl})`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 60px 60px -40px rgba(0, 0, 0, 0.2);
  }

  @media ${device.desktop} {
    height: 380px;
  }

  @media ${device.desktopL} {
    height: 500px;
  }
`

export const HomeVisitMobileImage = styled.img`
  width: 100%;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  height: 400px;
  object-fit: cover;

  @media ${device.tabletL} {
    display: none;
  }
`

export const Text = styled.div`
  padding: 20px 20px 40px 20px;

  h2 {
    margin-bottom: 10px;
  }


  @media ${device.tabletL} {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 380px;
    transform: translateY(-50%);
    padding: 0;
  }

  @media ${device.desktop} {
    width: 480px;

    h2 {
      margin-bottom: 20px;
    }
  }

  @media ${device.desktopL} {
    width: 500px;
    right: 50px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 21.6px;
  background: linear-gradient(93.02deg, #CB56C6 -1.22%, #84379E 88.05%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;

  @media ${device.tabletL} {
    margin-bottom: 10px;
  }

  @media ${device.desktop} {
    margin-bottom: 30px;
  }
`
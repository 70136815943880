import styled from 'styled-components'
import { device } from "./global-styles/mediaQueries";

const Wrapper = styled.div`
  margin-top: 40px;

  h5 {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 21.6px;
    background: linear-gradient(93.02deg, #CB56C6 -1.22%, #84379E 88.05%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media ${device.tabletL} {
    margin-bottom: 0;
  }

  @media ${device.desktopS} {
    h5 {
      margin-bottom: 20px;
    }
  }
`

const WorikingDays = styled.div`
  display: flex;
  
  justify-content: space-between;
  max-width: 445px;

  @media ${device.tabletL} {
    width: 400px;
  }
`

const Labels = styled.div`
  span {
    font-size: 16px;
  }
  p {
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    color: #565E6A;
  }
`

export default function WorkingHoursHero() {
  return (
    <Wrapper>
      <h5>RADNO VREME</h5>
      <WorikingDays>
        <Labels>
          <span>Pon-pet</span>
          <p>07-19</p>
        </Labels>

        <Labels>
          <span>Sub</span>
          <p>07-14</p>
        </Labels>

        <Labels>
          <span>Nedelja</span>
          <p>Neradni dan</p>
        </Labels>
      </WorikingDays>
    </Wrapper>
  )
}

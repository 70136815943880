import styled from "styled-components";
import { device } from "./global-styles/mediaQueries";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px 10px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 16px -2px rgba(49, 65, 97, 0.15);
  z-index: 20;

  @media ${device.tablet} {
    padding: 15px 30px 10px 30px;
  }

  @media ${device.desktopS} {
    padding: 20px 40px 20px 40px;
  }
`

export const LogoWrapper = styled.div`
  cursor: pointer;
  svg {
    width: 120px;
  }

  @media ${device.desktopS} {
    svg {
      width: 170px;
    }
  }
`

export const MobileMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 65px;
  right: ${({ isOpen }) => isOpen ? '0px' : '-100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 65px);
  width: 100%;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: right .3s ease-in-out;
  z-index: 10;
  overflow: scroll;
  
  @media ${device.desktopS} {
    display: none;
  }
`

export const DesktopMenu = styled.div`
  display: none;

  @media ${device.desktopS} {
    display: flex;
  }
`

export const Links = styled.a<{ isDesktop: boolean }>`
  display: block;
  font-family: ${({ theme }) => theme.fonts.workSans};
  font-size: ${({ isDesktop }) => isDesktop ? '16px' : '22px'};
  color: ${({ theme }) => theme.colors.navLink};
  text-decoration: none;
  transition: opacity .3s;
  margin-bottom: ${({ isDesktop }) => isDesktop ? '0px' : '25px'};
  
  &:last-of-type {
    margin-right: 0;
  }
  
  :hover {
    opacity: .7;
  }
  
  @media ${device.desktopS} {
    margin-right: ${({ isDesktop }) => isDesktop ? '40px' : '0px'};
  }

  @media ${device.desktopL} {
    margin-right: ${({ isDesktop }) => isDesktop ? '60px' : '0px'};
  }
`

export const PhoneNumberWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
  align-items: center;
  padding-top: ${({ isDesktop }) => isDesktop ? '0px' : '60px'};
  margin-bottom: 40px;

  a {
    margin-bottom: ${({ isDesktop }) => isDesktop ? '0px' : '20px'};
    margin-right: ${({ isDesktop }) => isDesktop ? '10px' : '0px'};

    &:last-of-type {
      margin-bottom: 0px;
      margin-right: 0px;
    }
  }

  @media ${device.desktopS} {
    margin-bottom: 0px;
  }
`

export const LinkWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
  padding-top: ${({ isDesktop }) => isDesktop ? '0px' : '40px'};
  margin-bottom: ${({ isDesktop }) => isDesktop ? '0px' : '60px'};
`
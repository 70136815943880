import React from 'react'

import Socials from './Socials'

import { ReactComponent as Logo } from '../assets/fluid-logo-gray.svg'

import { ContentWrapper } from './global-styles/globalComponents.styled'

import {
  FooterWrapper,
  LogoWrapper,
  InformationAndWorkingHoursWrapper,
  Information,
  SingleInformation,
  WorkingHoursWrapper,
  InfoLabel,
  WorkingHoursHeader,
  WorkingHoursText,
  WorkingHoursTextWrapper,
  SubFooterWrapper,
  SubFooterLabel,
  PhoneNum
} from './Footer.styled'

export default function Footer() {
  return (
    <FooterWrapper>
      <ContentWrapper>

        <InformationAndWorkingHoursWrapper>
          <div>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>


            <Information>
              <SingleInformation>
                <InfoLabel>ADRESA</InfoLabel>
                <PhoneNum target='_blank' rel="noreferrer" href="https://www.google.com/maps/place/Fluid+Lab/@45.8342111,20.4718228,18z/data=!3m1!4b1!4m6!3m5!1s0x4744df26d216f541:0x5953468bb63ee841!8m2!3d45.8342098!4d20.4726889!16s%2Fg%2F11btnjymr3?hl=en-GB">Kralja Petra I 72</PhoneNum>
                <PhoneNum target='_blank' rel="noreferrer" href="https://www.google.com/maps/place/Fluid+Lab/@45.8342111,20.4718228,18z/data=!3m1!4b1!4m6!3m5!1s0x4744df26d216f541:0x5953468bb63ee841!8m2!3d45.8342098!4d20.4726889!16s%2Fg%2F11btnjymr3?hl=en-GB">23300 Kikinda, Srbija</PhoneNum>
              </SingleInformation>

              <SingleInformation>
                <InfoLabel>TELEFON</InfoLabel>
                <PhoneNum href="tel:+381693146331">069-314-6-331</PhoneNum>
                <PhoneNum href="tel:+381230423113">0230-423-113</PhoneNum>
              </SingleInformation>

              <SingleInformation>
                <InfoLabel>EMAIL</InfoLabel>
                <a href="mailto:fluid.fl@gmail.com">fluid.fl@gmail.com</a>
              </SingleInformation>
            </Information>
          </div>

          <WorkingHoursWrapper>
            <WorkingHoursHeader>
              <InfoLabel>RADNO VREME</InfoLabel>
            </WorkingHoursHeader>

            <WorkingHoursTextWrapper>
              <WorkingHoursText>Pon-pet</WorkingHoursText>
              <WorkingHoursText isBold={true}>07:00-19:00</WorkingHoursText>
            </WorkingHoursTextWrapper>

            <WorkingHoursTextWrapper>
              <WorkingHoursText>Sub</WorkingHoursText>
              <WorkingHoursText isBold={true}>07:00-14:00</WorkingHoursText>
            </WorkingHoursTextWrapper>

            <WorkingHoursTextWrapper>
              <WorkingHoursText isBold={true}>Nedelja je neradna.</WorkingHoursText>
            </WorkingHoursTextWrapper>
          </WorkingHoursWrapper>

        </InformationAndWorkingHoursWrapper>

        <SubFooterWrapper>
          <SubFooterLabel>{`© FluidLab ${new Date().getFullYear()}.`}</SubFooterLabel>
          <Socials themeColor='gray' sizeInPx={24} />
        </SubFooterWrapper>
      </ContentWrapper>

    </FooterWrapper>
  )
}

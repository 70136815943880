import styled from "styled-components";
import { device } from "./mediaQueries";

export const ContentWrapper = styled.div`
  padding: 0px 20px;

  @media ${device.tablet} {
    padding: 0px 30px;
  }
  @media ${device.desktopS} {
    padding: 0px 0px;
    max-width: 900px;
    margin: 0 auto;
  }

  @media ${device.desktop} {
    max-width: 1125px;
  }
  
  @media ${device.desktopL} {
    max-width: 1340px;
  }
`

export const Paragraph = styled.p`
  color: #30353d;
  font-size: 14px;
  line-height: 25px;
  max-width: 650px;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const SectionLabel = styled.label`
  display: block;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const SectionTitle = styled.h2`
  font-size: 30px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.title};

  @media ${device.tablet} {
    font-size: 45px;
  }

  @media ${device.desktop} {
    font-size: 60px;
    line-height: 60px;
  }
`
import HomeHero from "../components/page-sections/HomeHero"
import Map from "../components/Map"
import Analisys from "../components/page-sections/Analisys"
import HomeVisits from "../components/page-sections/HomeVisits"
import AboutUs from "../components/page-sections/AboutUs"

export default function Homepage() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <HomeHero />
      <HomeVisits />
      <AboutUs />
      <Analisys />
      <Map />
    </div>
  )
}

import styled from "styled-components";
import { device } from "../global-styles/mediaQueries";


export const AnalisysWrapper = styled.div`
  padding: 60px 0px 80px 0px;

  @media ${device.tablet} {
    padding: 100px 0px;
  }

  @media ${device.desktopS} {
    padding: 150px 0;
  }

  @media ${device.desktop} {
    padding: 200px 0;
  }

  @media ${device.desktopL} {
    padding: 250px 0;
  }
`

export const SectionWrapper = styled.div`
  @media ${device.tabletL} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media ${device.desktop} {
    max-width: 950px;
    margin: 0 auto;
  }
`

export const ImageWrapper = styled.div`
  margin-bottom: 20px;

  .technician {
    display: none;
  }

  .element-1 {
    display: none;
  }

  .element-2 {
    display: none;
  }

  @media ${device.tablet} {
    margin-bottom: 40px;
  }

  @media ${device.tabletL} {
    margin-bottom: 0px;
    margin-right: 30px;
    
    .technician {
      display: block;
      min-width: 350px;
      height: auto;
      box-shadow: 0px 50px 50px -50px rgba(39, 39, 39, 0.25);
    }
  }

  @media ${device.desktopS} {
    position: relative;


    .element-1 {
      display: block;
      position: absolute;
      top: -40px;
      left: -100px;
      z-index: -1;
    }

    .element-2 {
      display: block;
      position: absolute;
      right: -58px;
      bottom: -58px;
    }
  }
`

export const TextWrapper = styled.div`

  h2 {
    margin-bottom: 15px;
    margin-top: 5px;
  }

  p {
    margin-bottom: 25px;
  }

  @media ${device.tablet} {
    h2 {
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 35px;
    }
  }

  @media ${device.tabletL} {
    max-width: 450px;

    h2 {
      margin-bottom: 20px;
    }
  }

  @media ${device.desktop} {
    p {
      margin-bottom: 40px;
    }
  }
`

export const AnalisysTypeWrapper = styled.div`
  @media ${device.tabletL} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const SingleAnalisysType = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  
  label {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.pinkText};
  }

  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  @media ${device.desktop} {
    margin-bottom: 0px;
    margin-top: 20px;

    label {
      font-size: 18px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
`
import React from 'react'

import { ContentWrapper, SectionTitle, Paragraph } from '../global-styles/globalComponents.styled'

import {
  HomeVisitsWrapper,
  ContentContainer,
  HomeVisitMobileImage,
  HomeVisitDesktopImage,
  Text,
  Label
} from './HomeVisits.styled'

import HomeVisitDesktop from '../../assets/home-visit-desktop.png'
import HomeVisitMobile from '../../assets/home-visit-mobile.png'


export default function HomeVisits() {
  return (
    <HomeVisitsWrapper id="kucne-posete">
      <ContentWrapper>
        <ContentContainer>
          <div style={{ borderRadius: '12px', backgroundColor: "#EBF0F8" }}>
            <HomeVisitDesktopImage imageUrl={HomeVisitDesktop} />
          </div>
          <HomeVisitMobileImage src={HomeVisitMobile} alt="Home visits" />
          <Text>
            <SectionTitle>Kućne posete</SectionTitle>
            <Label>Dolazak tehničara na kućnu adresu u kikindskoj opštini</Label>
            <Paragraph>Razumemo da je za neke pacijente poseta laboratoriji izazov, bilo da se radi o udaljenosti, zdravstvenom stanju ili vremenskim ograničenjima. Zbog toga smo odlučili da ponudimo kućne posete za naše pacijente.</Paragraph>
          </Text>
        </ContentContainer>
      </ContentWrapper>
    </HomeVisitsWrapper>
  )
}

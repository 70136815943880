const size = {
  mobile: '360px',
  tablet: '580px',
  tabletL: '840px',
  desktopS: '1024px',
  desktop: '1440px',
  desktopL: '1920px'
}

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  desktopS: `(min-width: ${size.desktopS})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`
};
import styled from "styled-components";
import { device } from "./global-styles/mediaQueries";

export const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.text};
  padding: 30px 0 0 0;

  @media ${device.tabletL} {
    padding: 50px 0 0 0;
  }

  @media ${device.desktopS} {
    padding: 80px 0 0 0;
  }
`

export const LogoWrapper = styled.div`
  margin-bottom: 30px;
  svg {
    width: 180px;
  }

  @media ${device.tabletL} {
    margin-bottom: 40px;
    svg {
      width: 210px;
    }
  }
`

export const PhoneNum = styled.a`
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  line-height: 25px;
  max-width: 650px;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const InformationAndWorkingHoursWrapper = styled.div`
  padding-bottom: 20px;
  
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${device.tabletL} {
    padding-bottom: 40px;
  }

  @media ${device.desktopS} {
    padding-bottom: 60px;
  }
`

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media ${device.tabletL} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  @media ${device.desktopS} {
    width: 500px;
  }

  @media ${device.desktop} {
    width: 600px;
  }
`

export const InfoLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  color: white;
  font-weight: 700;
  font-size: 12px;
`

export const SingleInformation = styled.div`
  margin-bottom: 20px;
  width: fit-content;
  p {
    margin-bottom: 5px;
    color: white;
    opacity: .5;
    font-size: 14px;
    line-height: 14px;
  }
  a {
    display: block;
    text-decoration: none;
    color: white;
    opacity: .5;
    font-size: 14px;
  }

  @media ${device.tabletL} {
    margin-right: 30px;
  }
`

export const WorkingHoursWrapper = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.lightGray};

  @media ${device.tablet} {
    width: 300px;
    flex-shrink: 0;
    height: fit-content;
  }
`

export const WorkingHoursHeader = styled.div`
  position: relative;
  margin-bottom: 20px;

  &:after {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: .1;
    content: "";
  }
`

export const WorkingHoursText = styled.p<{ isBold?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  font-weight: ${({ isBold }) => !!isBold ? '700' : '400'};
`

export const WorkingHoursTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`

export const SubFooterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: .1;
    content: "";
  }
`

export const SubFooterLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
`

export const Socials = styled.div`
  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${({ theme }) => theme.colors.white};
      &:last-of-type {
        fill: transparent;
      }
    }
  }
`
import React from 'react'

import WorkingHoursHero from '../WorkingHoursHero'

import { ContentWrapper, Paragraph, SectionLabel } from '../global-styles/globalComponents.styled'
import {
  HomeHeroWrapper,
  TextWrapper,
  LabelWrapper,
  Title,
  ImageWrapper,
  BackgroundImageWrapper,
  BackgroundImageContainer
} from './HomeHero.styled'

import HeroImg from '../../assets/hero-image.png'
import HeroIcon from '../../assets/hero-icon.png'
import BackgroundElement1 from '../../assets/hero-paralax-1.png'
import BackgroundElement2 from '../../assets/hero-paralax-2.png'
import BackgroundElement3 from '../../assets/hero-paralax-3.png'


export default function HomeHero() {
  return (
    <ContentWrapper>
      <HomeHeroWrapper>
        <TextWrapper>
          <LabelWrapper><SectionLabel>BIOHEMIJSKA LABORATORIJA KIKINDA</SectionLabel></LabelWrapper>
          <Title>Mi brinemo o vašem zdravlju.</Title>
          <Paragraph>Fluidlab laboratorija se nalazi u samom centru Kikinde, i nudi vam brzu i stručnu uslugu sa širokim spektrom analiza. Naša ekipa vrhunskih stručnjaka u oblasti biohemije pruža najbolju uslugu kako bi se osigurala tačna dijagnoza i lečenje za sve naše pacijente.</Paragraph>

          <WorkingHoursHero />

        </TextWrapper>

        <ImageWrapper>
          <BackgroundImageWrapper>
            <BackgroundImageContainer>
              <img className='element-1' src={BackgroundElement1} alt="element 1" />
              <img className='element-2' src={BackgroundElement2} alt="element 2" />
              <img className='element-3' src={BackgroundElement3} alt="element 3" />
            </BackgroundImageContainer>
          </BackgroundImageWrapper>

          <img src={HeroImg} className='laborant' alt="laborant" />

          <img className='elment-4' src={HeroIcon} alt="element 4" />
        </ImageWrapper>

      </HomeHeroWrapper>
    </ContentWrapper>
  )
}

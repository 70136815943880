import { useState, useEffect } from 'react'
import smoothscroll from 'smoothscroll-polyfill';

import PhoneNumber from './PhoneNumber'
import HamburgerMenu from './HamburgerMenu'
import Socials from './Socials';

import useMediaQuery from '../hooks/useMediaQuery'

import { ReactComponent as Logo } from '../assets/fluid-logo-pink.svg'

import {
  HeaderWrapper,
  MobileMenu,
  DesktopMenu,
  Links,
  LogoWrapper,
  PhoneNumberWrapper,
  LinkWrapper
} from './Header.styled'



export default function Header() {

  const isDesktop = useMediaQuery('desktop');

  const [isOpen, setIsOpen] = useState(false);

  const links = [
    { text: 'Kućne posete', link: 'kucne-posete' },
    { text: 'O nama', link: 'o-nama' },
    { text: 'Analize', link: 'analize' }
  ]


  function scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }


  function smoothScrollTo(elementId: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    e.preventDefault();

    const target = document.getElementById(elementId);
    const headerOffset = isDesktop ? 80 : 65
    const sectionOffset = 50

    if (!target) {
      console.warn(`Element with ID "${elementId}" not found.`);
      return;
    }

    let y: number = target.getBoundingClientRect().top + window.scrollY - headerOffset - sectionOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    if (!isDesktop && isOpen) {
      setIsOpen(false)
    }
  }


  useEffect(() => {
    smoothscroll.polyfill() // needed for safari 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (isOpen)
      document.body.classList.add('no-scroll')
    else
      document.body.classList.remove('no-scroll')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])


  useEffect(() => {
    if (isDesktop) {
      isOpen && setIsOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])


  return (
    <HeaderWrapper>
      <LogoWrapper onClick={scrollToTop}><Logo /></LogoWrapper>

      <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />

      <DesktopMenu>
        <LinkWrapper isDesktop={true}>
          {
            links.map((link, index) =>
              <Links
                key={index}
                isDesktop={true}
                href={link.link}
                onClick={(e) => smoothScrollTo(link.link, e)}
              >
                {link.text}
              </Links>
            )
          }
        </LinkWrapper>
      </DesktopMenu>

      <DesktopMenu>
        <PhoneNumberWrapper isDesktop={true}>
          <PhoneNumber phone='069-314-6-331' callNumber="+381693146331" />
          <PhoneNumber phone="0230-423-113" callNumber="+381230423113" />
        </PhoneNumberWrapper>
      </DesktopMenu>

      {
        <MobileMenu isOpen={isOpen}>
          <PhoneNumberWrapper isDesktop={false}>
            <PhoneNumber phone='069-314-6-331' callNumber="+381693146331" />
            <PhoneNumber phone="0230-423-113" callNumber="+381230423113" />
          </PhoneNumberWrapper>

          <LinkWrapper isDesktop={false}>
            {
              links.map((link, index) =>
                <Links
                  key={index}
                  isDesktop={false}
                  href={link.link}
                  onClick={(e) => smoothScrollTo(link.link, e)}
                >
                  {link.text}
                </Links>
              )
            }
          </LinkWrapper>


          <Socials themeColor='white' sizeInPx={24} />
        </MobileMenu>
      }
    </HeaderWrapper>
  )
}

import React from 'react'
import styled, { keyframes } from 'styled-components'

import { ReactComponent as PhoneIcon } from '../assets/call.svg'


const RingAnimation = keyframes`
  0% {
    transform: rotate(0) scale(1) skew(1deg)
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg)
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg)
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg)
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg)
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg)
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  transition: all .2s;
`


const PhoneWrapper = styled.a`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 10px;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 30px;
  text-decoration: none;
  transition: all .2s;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
      transition: all .2s;
    }
  }
  
  :hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};

    svg {
      animation: ${RingAnimation} 1s infinite;
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }

    ${IconWrapper} {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`

export default function PhoneNumber({ phone, callNumber }: { phone: string, callNumber: string }) {
  return (
    <PhoneWrapper href={`tel:${callNumber}`}>
      <IconWrapper>
        <PhoneIcon />
      </IconWrapper>
      {phone}
    </PhoneWrapper>
  )
}

import {
  AboutUsWrapper,
  Content,
  TextContent,
  Image,
  BackgroundElement
} from './AboutUs.styled'
import { ContentWrapper, Paragraph, SectionLabel, SectionTitle } from '../global-styles/globalComponents.styled'

import Laboratory from '../../assets/lab-from-street.png'


export default function AboutUs() {
  return (
    <>
      <AboutUsWrapper>
        <ContentWrapper>
          <Content>
            <div id="o-nama"></div>
            <TextContent>
              <SectionLabel>FLUID LAB</SectionLabel>
              <SectionTitle>O nama</SectionTitle>
              <Paragraph>Naša biohemijska laboratorija posvećena je pružanju vrhunskih testova i usluga koji vam pomažu u očuvanju vašeg zdravlja. Naša ekipa stručnih biohemičara i medicinskih tehničara ima godine iskustva u dijagnostikovanju i liječenju različitih zdravstvenih stanja.</Paragraph>
              <Paragraph>Pored testova, mi smo ovdje da vam pružimo i podršku u vašem zdravstvenom putovanju. Naši stručnjaci su uvijek spremni da odgovore na vaša pitanja i pruže vam savjetovanje i tretmane. Naš cilj je da osiguramo da naši pacijenti budu potpuno informisani o svom zdravstvenom stanju i da dobiju najbolju moguću njegu.</Paragraph>
            </TextContent>

            <Image src={Laboratory} alt="Fluid Lab" />
          </Content>
        </ContentWrapper>
        <svg width="1440" height="478" viewBox="0 0 1440 478" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2" d="M53 230.99V200.19L26.33 184.8L-0.329956 200.19V230.99L26.33 246.38L53 230.99Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M106.33 230.99V200.19L79.67 184.8L53 200.19V230.99L79.67 246.38L106.33 230.99Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M133 184.79V153.99L106.33 138.6L79.67 153.99V184.79L106.33 200.18L133 184.79Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M186.33 184.79V153.99L159.67 138.6L133 153.99V184.79L159.67 200.18L186.33 184.79Z" fill="#D0C1D3" />
          <path opacity="0.25" d="M213 138.58V107.79L186.33 92.39L159.67 107.79V138.58L186.33 153.98L213 138.58Z" fill="#D0C1D3" />
          <path opacity="0.15" d="M239.67 92.3799V61.5799L213 46.1899L186.33 61.5799V92.3799L213 107.77L239.67 92.3799Z" fill="#D0C1D3" />
          <path opacity="0.1" d="M293 92.3799V61.5799L266.33 46.1899L239.67 61.5799V92.3799L266.33 107.77L293 92.3799Z" fill="#D0C1D3" />
          <path opacity="0.1" d="M266.33 46.19V15.4L239.67 0L213 15.4V46.19L239.67 61.58L266.33 46.19Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M213 230.99V200.19L186.33 184.8L159.67 200.19V230.99L186.33 246.38L213 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M239.67 184.79V153.99L213 138.6L186.33 153.99V184.79L213 200.18L239.67 184.79Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M319.67 230.99V200.19L293 184.8L266.33 200.19V230.99L293 246.38L319.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M373 138.58V107.79L346.33 92.39L319.67 107.79V138.58L346.33 153.98L373 138.58Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M399.67 92.3799V61.5799L373 46.1899L346.33 61.5799V92.3799L373 107.77L399.67 92.3799Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M373 230.99V200.19L346.33 184.8L319.67 200.19V230.99L346.33 246.38L373 230.99Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M586.33 230.99V200.19L559.67 184.8L533 200.19V230.99L559.67 246.38L586.33 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M639.67 230.99V200.19L613 184.8L586.33 200.19V230.99L613 246.38L639.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M666.33 184.79V153.99L639.67 138.6L613 153.99V184.79L639.67 200.18L666.33 184.79Z" fill="#D0C1D3" />
          <path opacity="0.18" d="M693 230.99V200.19L666.33 184.8L639.67 200.19V230.99L666.33 246.38L693 230.99Z" fill="#D0C1D3" />
          <path opacity="0.25" d="M719.67 184.79V153.99L693 138.6L666.33 153.99V184.79L693 200.18L719.67 184.79Z" fill="#D0C1D3" />
          <path opacity="0.15" d="M693 138.58V107.79L666.33 92.39L639.67 107.79V138.58L666.33 153.98L693 138.58Z" fill="#D0C1D3" />
          <path opacity="0.15" d="M746.33 138.58V107.79L719.67 92.39L693 107.79V138.58L719.67 153.98L746.33 138.58Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M586.33 138.58V107.79L559.67 92.39L533 107.79V138.58L559.67 153.98L586.33 138.58Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M746.33 230.99V200.19L719.67 184.8L693 200.19V230.99L719.67 246.38L746.33 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M799.67 230.99V200.19L773 184.8L746.33 200.19V230.99L773 246.38L799.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M826.33 184.79V153.99L799.67 138.6L773 153.99V184.79L799.67 200.18L826.33 184.79Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M906.33 230.99V200.19L879.67 184.8L853 200.19V230.99L879.67 246.38L906.33 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M933 184.79V153.99L906.33 138.6L879.67 153.99V184.79L906.33 200.18L933 184.79Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M959.67 230.99V200.19L933 184.8L906.33 200.19V230.99L933 246.38L959.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M1119.67 230.99V200.19L1093 184.8L1066.33 200.19V230.99L1093 246.38L1119.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.15" d="M1013 138.6V107.81L986.33 92.41L959.67 107.81V138.6L986.33 153.99L1013 138.6Z" fill="#D0C1D3" />
          <path opacity="0.15" d="M1066.33 138.58V107.79L1039.67 92.39L1013 107.79V138.58L1039.67 153.98L1066.33 138.58Z" fill="#D0C1D3" />
          <path opacity="0.15" d="M1039.67 92.39V61.6L1013 46.21L986.33 61.6V92.39L1013 107.79L1039.67 92.39Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M1173 230.99V200.19L1146.33 184.8L1119.67 200.19V230.99L1146.33 246.38L1173 230.99Z" fill="#D0C1D3" />
          <path opacity="0.1" d="M1199.67 184.79V153.99L1173 138.6L1146.33 153.99V184.79L1173 200.18L1199.67 184.79Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M1253 184.79V153.99L1226.33 138.6L1199.67 153.99V184.79L1226.33 200.18L1253 184.79Z" fill="#D0C1D3" />
          <path opacity="0.1" d="M1226.33 138.6V107.81L1199.67 92.41L1173 107.81V138.6L1199.67 153.99L1226.33 138.6Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M1226.33 230.99V200.19L1199.67 184.8L1173 200.19V230.99L1199.67 246.38L1226.33 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M1279.67 230.99V200.19L1253 184.8L1226.33 200.19V230.99L1253 246.38L1279.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M1306.33 184.79V153.99L1279.67 138.6L1253 153.99V184.79L1279.67 200.18L1306.33 184.79Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1333 230.99V200.19L1306.33 184.8L1279.67 200.19V230.99L1306.33 246.38L1333 230.99Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1439.67 230.99V200.19L1413 184.8L1386.33 200.19V230.99L1413 246.38L1439.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M79.67 277.19V246.4L53 231L26.33 246.4V277.19L53 292.59L79.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M26.33 277.19V246.4L-0.33 231L-27 246.4V277.19L-0.33 292.59L26.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M186.33 277.19V246.4L159.67 231L133 246.4V277.19L159.67 292.59L186.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M239.67 277.19V246.4L213 231L186.33 246.4V277.19L213 292.59L239.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M293 277.19V246.4L266.33 231L239.67 246.4V277.19L266.33 292.59L293 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M346.33 277.19V246.4L319.67 231L293 246.4V277.19L319.67 292.59L346.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M399.67 277.19V246.4L373 231L346.33 246.4V277.19L373 292.59L399.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M559.67 277.19V246.4L533 231L506.33 246.4V277.19L533 292.59L559.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M479.67 230.99V200.19L453 184.8L426.33 200.19V230.99L453 246.38L479.67 230.99Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M613 277.19V246.4L586.33 231L559.67 246.4V277.19L586.33 292.59L613 277.19Z" fill="#D0C1D3" />
          <path opacity="0.18" d="M666.33 277.19V246.4L639.67 231L613 246.4V277.19L639.67 292.59L666.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M719.67 277.19V246.4L693 231L666.33 246.4V277.19L693 292.59L719.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M773 277.19V246.4L746.33 231L719.67 246.4V277.19L746.33 292.59L773 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M826.33 277.19V246.4L799.67 231L773 246.4V277.19L799.67 292.59L826.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M879.67 277.19V246.4L853 231L826.33 246.4V277.19L853 292.59L879.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M933 277.19V246.4L906.33 231L879.67 246.4V277.19L906.33 292.59L933 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M986.33 277.19V246.4L959.67 231L933 246.4V277.19L959.67 292.59L986.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.2" d="M1093 277.19V246.4L1066.33 231L1039.67 246.4V277.19L1066.33 292.59L1093 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1146.33 277.19V246.4L1119.67 231L1093 246.4V277.19L1119.67 292.59L1146.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1199.67 277.19V246.4L1173 231L1146.33 246.4V277.19L1173 292.59L1199.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1253 277.19V246.4L1226.33 231L1199.67 246.4V277.19L1226.33 292.59L1253 277.19Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1359.67 277.19V246.4L1333 231L1306.33 246.4V277.19L1333 292.59L1359.67 277.19Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1413 277.19V246.4L1386.33 231L1359.67 246.4V277.19L1386.33 292.59L1413 277.19Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1466.33 277.19V246.4L1439.67 231L1413 246.4V277.19L1439.67 292.59L1466.33 277.19Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M53 323.39V292.6L26.33 277.2L-0.329956 292.6V323.39L26.33 338.79L53 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M106.33 323.39V292.6L79.67 277.2L53 292.6V323.39L79.67 338.79L106.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M159.67 323.39V292.6L133 277.2L106.33 292.6V323.39L133 338.79L159.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M213 323.39V292.6L186.33 277.2L159.67 292.6V323.39L186.33 338.79L213 323.39Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M266.33 323.39V292.6L239.67 277.2L213 292.6V323.39L239.67 338.79L266.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M319.67 323.39V292.6L293 277.2L266.33 292.6V323.39L293 338.79L319.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M373 323.39V292.6L346.33 277.2L319.67 292.6V323.39L346.33 338.79L373 323.39Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M426.33 323.39V292.6L399.67 277.2L373 292.6V323.39L399.67 338.79L426.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M479.67 323.39V292.6L453 277.2L426.33 292.6V323.39L453 338.79L479.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M533 323.39V292.6L506.33 277.2L479.67 292.6V323.39L506.33 338.79L533 323.39Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M586.33 323.39V292.6L559.67 277.2L533 292.6V323.39L559.67 338.79L586.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M639.67 323.39V292.6L613 277.2L586.33 292.6V323.39L613 338.79L639.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M693 323.39V292.6L666.33 277.2L639.67 292.6V323.39L666.33 338.79L693 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M746.33 323.39V292.6L719.67 277.2L693 292.6V323.39L719.67 338.79L746.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M853 323.39V292.6L826.33 277.2L799.67 292.6V323.39L826.33 338.79L853 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M906.33 323.39V292.6L879.67 277.2L853 292.6V323.39L879.67 338.79L906.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M959.67 323.39V292.6L933 277.2L906.33 292.6V323.39L933 338.79L959.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1013 323.39V292.6L986.33 277.2L959.67 292.6V323.39L986.33 338.79L1013 323.39Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1066.33 323.39V292.6L1039.67 277.2L1013 292.6V323.39L1039.67 338.79L1066.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.12" d="M1119.67 323.39V292.6L1093 277.2L1066.33 292.6V323.39L1093 338.79L1119.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M1173 323.39V292.6L1146.33 277.2L1119.67 292.6V323.39L1146.33 338.79L1173 323.39Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M1226.33 323.39V292.6L1199.67 277.2L1173 292.6V323.39L1199.67 338.79L1226.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1279.67 323.39V292.6L1253 277.2L1226.33 292.6V323.39L1253 338.79L1279.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1333 323.39V292.6L1306.33 277.2L1279.67 292.6V323.39L1306.33 338.79L1333 323.39Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1386.33 323.39V292.6L1359.67 277.2L1333 292.6V323.39L1359.67 338.79L1386.33 323.39Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1439.67 323.39V292.6L1413 277.2L1386.33 292.6V323.39L1413 338.79L1439.67 323.39Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M79.67 369.6V338.8L53 323.41L26.33 338.8V369.6L53 384.99L79.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M26.33 369.6V338.8L-0.33 323.41L-27 338.8V369.6L-0.33 384.99L26.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M133 369.6V338.8L106.33 323.41L79.67 338.8V369.6L106.33 384.99L133 369.6Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M186.33 369.6V338.8L159.67 323.41L133 338.8V369.6L159.67 384.99L186.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.08" d="M239.67 369.6V338.8L213 323.41L186.33 338.8V369.6L213 384.99L239.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M293 369.6V338.8L266.33 323.41L239.67 338.8V369.6L266.33 384.99L293 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M346.33 369.6V338.8L319.67 323.41L293 338.8V369.6L319.67 384.99L346.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M399.67 369.6V338.8L373 323.41L346.33 338.8V369.6L373 384.99L399.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M453 369.6V338.8L426.33 323.41L399.67 338.8V369.6L426.33 384.99L453 369.6Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M506.33 369.6V338.8L479.67 323.41L453 338.8V369.6L479.67 384.99L506.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M559.67 369.6V338.8L533 323.41L506.33 338.8V369.6L533 384.99L559.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M613 369.6V338.8L586.33 323.41L559.67 338.8V369.6L586.33 384.99L613 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M666.33 369.6V338.8L639.67 323.41L613 338.8V369.6L639.67 384.99L666.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M719.67 369.6V338.8L693 323.41L666.33 338.8V369.6L693 384.99L719.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M879.67 369.6V338.8L853 323.41L826.33 338.8V369.6L853 384.99L879.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M933 369.6V338.8L906.33 323.41L879.67 338.8V369.6L906.33 384.99L933 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M986.33 369.6V338.8L959.67 323.41L933 338.8V369.6L959.67 384.99L986.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1039.67 369.6V338.8L1013 323.41L986.33 338.8V369.6L1013 384.99L1039.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1093 369.6V338.8L1066.33 323.41L1039.67 338.8V369.6L1066.33 384.99L1093 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1199.67 369.6V338.8L1173 323.41L1146.33 338.8V369.6L1173 384.99L1199.67 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1253 369.6V338.8L1226.33 323.41L1199.67 338.8V369.6L1226.33 384.99L1253 369.6Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1306.33 369.6V338.8L1279.67 323.41L1253 338.8V369.6L1279.67 384.99L1306.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1413 369.6V338.8L1386.33 323.41L1359.67 338.8V369.6L1386.33 384.99L1413 369.6Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1466.33 369.6V338.8L1439.67 323.41L1413 338.8V369.6L1439.67 384.99L1466.33 369.6Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M53 415.78V384.99L26.33 369.6L-0.329956 384.99V415.78L26.33 431.18L53 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M106.33 415.78V384.99L79.67 369.6L53 384.99V415.78L79.67 431.18L106.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M159.67 415.78V384.99L133 369.6L106.33 384.99V415.78L133 431.18L159.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M213 415.78V384.99L186.33 369.6L159.67 384.99V415.78L186.33 431.18L213 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M266.33 415.78V384.99L239.67 369.6L213 384.99V415.78L239.67 431.18L266.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M319.67 415.78V384.99L293 369.6L266.33 384.99V415.78L293 431.18L319.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M373 415.78V384.99L346.33 369.6L319.67 384.99V415.78L346.33 431.18L373 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M426.33 415.78V384.99L399.67 369.6L373 384.99V415.78L399.67 431.18L426.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M479.67 415.78V384.99L453 369.6L426.33 384.99V415.78L453 431.18L479.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M533 415.78V384.99L506.33 369.6L479.67 384.99V415.78L506.33 431.18L533 415.78Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M586.33 415.78V384.99L559.67 369.6L533 384.99V415.78L559.67 431.18L586.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M639.67 415.78V384.99L613 369.6L586.33 384.99V415.78L613 431.18L639.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M693 415.78V384.99L666.33 369.6L639.67 384.99V415.78L666.33 431.18L693 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M746.33 415.78V384.99L719.67 369.6L693 384.99V415.78L719.67 431.18L746.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M799.67 415.78V384.99L773 369.6L746.33 384.99V415.78L773 431.18L799.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M853 415.78V384.99L826.33 369.6L799.67 384.99V415.78L826.33 431.18L853 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M906.33 415.78V384.99L879.67 369.6L853 384.99V415.78L879.67 431.18L906.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M959.67 415.78V384.99L933 369.6L906.33 384.99V415.78L933 431.18L959.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1013 415.78V384.99L986.33 369.6L959.67 384.99V415.78L986.33 431.18L1013 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1066.33 415.78V384.99L1039.67 369.6L1013 384.99V415.78L1039.67 431.18L1066.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.06" d="M1226.33 415.78V384.99L1199.67 369.6L1173 384.99V415.78L1199.67 431.18L1226.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1279.67 415.78V384.99L1253 369.6L1226.33 384.99V415.78L1253 431.18L1279.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1386.33 415.78V384.99L1359.67 369.6L1333 384.99V415.78L1359.67 431.18L1386.33 415.78Z" fill="#D0C1D3" />
          <path opacity="0.03" d="M1439.67 415.78V384.99L1413 369.6L1386.33 384.99V415.78L1413 431.18L1439.67 415.78Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M79.67 461.99V431.2L53 415.8L26.33 431.2V461.99L53 477.38L79.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M26.33 461.99V431.2L-0.33 415.8L-27 431.2V461.99L-0.33 477.38L26.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M133 461.99V431.2L106.33 415.8L79.67 431.2V461.99L106.33 477.38L133 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M186.33 461.99V431.2L159.67 415.8L133 431.2V461.99L159.67 477.38L186.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M239.67 461.99V431.2L213 415.8L186.33 431.2V461.99L213 477.38L239.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M293 461.99V431.2L266.33 415.8L239.67 431.2V461.99L266.33 477.38L293 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M346.33 461.99V431.2L319.67 415.8L293 431.2V461.99L319.67 477.38L346.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M399.67 461.99V431.2L373 415.8L346.33 431.2V461.99L373 477.38L399.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M453 461.99V431.2L426.33 415.8L399.67 431.2V461.99L426.33 477.38L453 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M506.33 461.99V431.2L479.67 415.8L453 431.2V461.99L479.67 477.38L506.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M559.67 461.99V431.2L533 415.8L506.33 431.2V461.99L533 477.38L559.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M613 461.99V431.2L586.33 415.8L559.67 431.2V461.99L586.33 477.38L613 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M666.33 461.99V431.2L639.67 415.8L613 431.2V461.99L639.67 477.38L666.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M719.67 461.99V431.2L693 415.8L666.33 431.2V461.99L693 477.38L719.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M773 461.99V431.2L746.33 415.8L719.67 431.2V461.99L746.33 477.38L773 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M826.33 461.99V431.2L799.67 415.8L773 431.2V461.99L799.67 477.38L826.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M879.67 461.99V431.2L853 415.8L826.33 431.2V461.99L853 477.38L879.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M933 461.99V431.2L906.33 415.8L879.67 431.2V461.99L906.33 477.38L933 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M986.33 461.99V431.2L959.67 415.8L933 431.2V461.99L959.67 477.38L986.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1039.67 461.99V431.2L1013 415.8L986.33 431.2V461.99L1013 477.38L1039.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1093 461.99V431.2L1066.33 415.8L1039.67 431.2V461.99L1066.33 477.38L1093 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1146.33 461.99V431.2L1119.67 415.8L1093 431.2V461.99L1119.67 477.38L1146.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1199.67 461.99V431.2L1173 415.8L1146.33 431.2V461.99L1173 477.38L1199.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1253 461.99V431.2L1226.33 415.8L1199.67 431.2V461.99L1226.33 477.38L1253 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1306.33 461.99V431.2L1279.67 415.8L1253 431.2V461.99L1279.67 477.38L1306.33 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1359.67 461.99V431.2L1333 415.8L1306.33 431.2V461.99L1333 477.38L1359.67 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1413 461.99V431.2L1386.33 415.8L1359.67 431.2V461.99L1386.33 477.38L1413 461.99Z" fill="#D0C1D3" />
          <path opacity="0.01" d="M1466.33 461.99V431.2L1439.67 415.8L1413 431.2V461.99L1439.67 477.38L1466.33 461.99Z" fill="#D0C1D3" />
        </svg>

      </AboutUsWrapper>
      <BackgroundElement></BackgroundElement>
    </>
  )
}

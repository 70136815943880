import React from 'react'
import styled from 'styled-components'
import { device } from './global-styles/mediaQueries'

import Header from './Header'
import Footer from './Footer'


const LayoutWrapper = styled.div`
  padding-top: 65px;

  @media ${device.desktopS} {
    padding-top: 80px;
  }
`

type LayoutProps = {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />

      <LayoutWrapper>
        {children}
      </LayoutWrapper>

      <Footer />
    </>
  )
}

import styled from "styled-components";
import { device } from '../global-styles/mediaQueries'

export const HomeHeroWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 60px;

  @media ${device.tablet} {
    padding-bottom: 80px;
    padding-top: 60px;
  }

  @media ${device.desktopS} {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 90px;
  }

  @media ${device.desktop} {
    padding-top: 120px;
    padding-bottom: 190px;
  }

  @media ${device.desktopL} {
    padding-bottom: 210px;
  }
`


export const TextWrapper = styled.div`
  @media ${device.tablet} {
    max-width: 650px;
  }
  @media ${device.desktopS} {
    margin-right: 60px;
    max-width: 500px;
    p {
      max-width: 480px;
    }
  }

  @media ${device.desktop} {
    max-width: 570px;
  }

  @media ${device.desktopL} {
    max-width: unset;
    p {
      max-width: 650px;
    }
  }
`

export const LabelWrapper = styled.div`
  margin-bottom: 15px;
`

export const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 40px;
  background: ${({ theme }) => theme.gradients.text};
  background-clip: text;
  background-size: 100%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;

  @media ${device.tablet} {
    font-size: 50px;
    line-height: 100%;
  }

  @media ${device.desktop} {
    font-size: 60px;
  }

  @media ${device.desktopL} {
    font-size: 70px;
  }
`


export const ImageWrapper = styled.div`
  display: none;

  @media ${device.desktopS} {
    position: relative;
    display: block;
    width: 350px;
    height: 350px;

    .laborant {
      position: relative;
      width: 350px;
      height: 350px;
      z-index: 2;
      box-shadow: 0px 50px 50px -50px rgba(39, 39, 39, 0.25);
    }

    .elment-4 {
      position: absolute;
      left: -25px;
      bottom: -25px;
      z-index: 3;
      width: 60px;
      height: 60px;
    }
  }

  @media ${device.desktop} {
    width: 450px;
    height: 450px;

    .laborant {
      position: relative;
      width: 450px;
      height: 450px;
      z-index: 2;
    }

    .elment-4 {
      left: -40px;
      bottom: -40px;
      z-index: 3;
      width: 80px;
      height: 80px;
    }
  }
`

export const BackgroundImageWrapper = styled.div`
  @media ${device.desktopS} {
    position: absolute;
    width: 350px;
    height: 350px;
  }

  @media ${device.desktop} {
    width: 450px;
    height: 450px;
  }
`

export const BackgroundImageContainer = styled.div`
@media ${device.desktopS} {
    position: relative;
    height: inherit;
    width: 350px;
    height: 350px;

    .element {
      &-1 {
        position: absolute;
        left: -30px;
        top: -20px;
      }
      &-2 {
        position: absolute;
        top: -40px;
        right: -60px;
        width: 100px;
      }
      &-3 {
        position: absolute;
        bottom: -80px;
        right: -60px;
      }
    }
  }
  
  @media ${device.desktop} {
    width: 450px;
    height: 450px;

    .element {
      &-1 {
        position: absolute;
        left: -30px;
        top: -20px;
      }
      &-2 {
        top: -55px;
        right: -120px;
        width: unset;
      }
      &-3 {
        bottom: -120px;
        right: -120px;
      }
    }
  }
`
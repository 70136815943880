import React from 'react'

import { ContentWrapper, Paragraph, SectionLabel, SectionTitle } from '../global-styles/globalComponents.styled'

import Technician from '../../assets/analisys-image.png'
import HeroIcon from '../../assets/hero-icon.png'
import BackgroundElement1 from '../../assets/hero-paralax-3.png'

import { ReactComponent as Checkmark } from '../../assets/checkmark.svg'

import {
  AnalisysWrapper,
  SectionWrapper,
  ImageWrapper,
  TextWrapper,
  AnalisysTypeWrapper,
  SingleAnalisysType
} from './Analisys.styled'

export default function Analisys() {

  const analisysTypes = ['Hematologija', 'Imunologija', 'Biohemijska', 'Alergologija', 'Hormoni', 'Genetika', 'Tumor markeri']

  return (
    <AnalisysWrapper>
      <ContentWrapper>
        <SectionWrapper>
          <ImageWrapper>
            <img className='element-1' src={BackgroundElement1} alt="background element" />
            <img className='technician' src={Technician} alt="Technician with epruvet" />
            <img className='element-2' src={HeroIcon} alt="background element" />
          </ImageWrapper>

          <TextWrapper>
            <div id="analize"></div>
            <SectionLabel>VISOK KVALITET</SectionLabel>
            <SectionTitle>Analize</SectionTitle>
            <Paragraph>Posvećeni smo pružanju pouzdanih i preciznih rezultata kako bismo podržali vaše zdravlje i dobrobit.<br />U našoj laboratoriji možete dobiti širok spektar analiza koje pokrivaju različite aspekte vašeg zdravlja i dijagnostike. Radimo sve analize iz sledećih oblasti:</Paragraph>

            <AnalisysTypeWrapper>
              {
                analisysTypes.map(analisysType =>
                  <SingleAnalisysType key={analisysType}>
                    <Checkmark />
                    <label>{analisysType}</label>
                  </SingleAnalisysType>
                )
              }
            </AnalisysTypeWrapper>
          </TextWrapper>
        </SectionWrapper>
      </ContentWrapper>
    </AnalisysWrapper>
  )
}

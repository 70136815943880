import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize'

export default createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
  }
  html {
    height: 100%;
  }
  body {
    height: 100%;
    & > iframe {
      display: none;
    }
  }
  .nws-modal {
    bottom: unset !important; 
  }
  #root {
    height: 100%;
  }
  .scrollhost {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .scrollhost::-webkit-scrollbar {
    display: none;
  }
  * {
    box-sizing: border-box;
  }
  h1, h2 ,h3 ,h4 ,h5 ,h6, label, span {
    font-family: 'Work Sans', sans-serif;
    margin: 0;
  }
  p, div {
    font-family: 'Inter', sans-serif;
    margin: 0;
  }
  .no-scroll {
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
`;

